<header id="header">
  <div class="col-md-auto">
    <a routerLink="/discover" class="logo-wrapper d-flex align-items-center h-100 text-decoration-none">
      <img class="logo" src="/assets/images/logo-audalize-music.png" alt="audalize">
    </a>
  </div>


  <div class="col" *ngIf="isShowSearchBar">
    <div class="search position-relative">
      <input [(ngModel)]="searchString" class="form-control text-end" type="text"
        placeholder="Search for songs and playlists" (keyup)="onSearchChange($event)" />
    </div>
  </div>  


  <!-- <div class="col">
    <div class="user-menu-button dropdown d-flex align-items-center justify-content-end h-100">
      <div class="flex-wrapper d-flex">

        <div *ngIf="user && isShowSearchBar" class="user-info-container d-flex">
          <div class="avatar-wrapper d-flex align-items-center position-relative">
            <img [src]="user.avatarImageUrl || '/assets/images/user-default-white.png'"
              appDefault="/assets/images/user-default-white.png" class="avatar rounded-circle" alt="User avatar">
          </div>

          <div class="user-info-wrapper d-flex flex-column align-items-start">
            <div class="user-role" *ngIf="!user.globalRoles && user.roles">{{ roleNames[user.roles[0]] }}</div>
            <div class="user-name">{{ user.displayName }}</div>
          </div>
        </div>

        <div class="action-item-icon d-flex align-items-center" *ngIf="isShowSearchBar">
          <div class="icon icon-menu" (click)="inputSideNav.toggle()"></div>
        </div>

      </div>
    </div>
  </div> -->

  <div class="col-auto pr-0">
    <div class="venue-container">
      <div class="venue-wrapper" (click)="changeVenue()">
        <p [title]="venue?.name" class="venue-name m-0 text-truncate">{{ venue?.name }}</p>
        <div class="icon icon-chevron-down"></div>
      </div>
    </div>
  </div>
</header>
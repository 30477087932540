export const environment = {
  environment        : 'development',
  playgroundEnabled  : true,
  production         : false,
  API_URL            : 'https://api.aws-dev.audalize.co/',
  API_UPLOAD_BASE_URL: 'https://api.aws-dev.audalize.co/upload',
  GRAPHQL_WS_URL     : 'wss://api.aws-dev.audalize.co/graphql',
  CHATKIT: {
    PUSHER_KEY       : 'aa42b2f846a818b7bf83',
    INSTANCE_LOCATOR : 'v1:us1:2153f99f-1989-42f9-9176-b7b7e7735d79',
    AUTH_API_URL     : 'https://api.aws-dev.audalize.co/chat'
  },
  SPOTIFY: {
    CLIENT_ID        : '0f11b6b445724a8aa1f64fdf7ba48444',
    API_URL          : 'https://api.spotify.com/v1/'
  },
  JUKEO_BASE_URL     : 'https://jukeo.aws-dev.audalize.com.au',
  MANAGE_APP        : 'https://manage.dev.audalize.co/',
  EXTERNAL_LOG: {
    ID: 'c4dde465a5894193bc0800b7988a1aa4',
    UUID: 'ff305f80d18c49b2a92c8bf89890c3ca'
  }
};

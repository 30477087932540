<div class="promoted-playlist-container" (click)="viewPlaylist()">
  <div class="promoted-playlist">
    <div class="quarter-images" *ngIf="playlist.promotedArtwork?.length === 6; else singleCover">
      <img *ngFor="let artwork of playlist.promotedArtwork" [src]="artwork"
        appDefault="/assets/images/playlist-cover-1.jpg" class="quarter-img" alt="Playlist artwork" />
    </div>
    <ng-template #singleCover>
      <img
        [src]="(playlist.promotedArtwork && playlist.promotedArtwork[0]) ? playlist.promotedArtwork[0] : '/assets/images/logo-gray.svg'"
        [class.default-cover]="!(playlist.promotedArtwork && playlist.promotedArtwork[0])" defaultClass="default-cover"
        class="cover-img" alt="Playlist artwork" />
    </ng-template>
  </div>

  <div class="mt-2">
    <h3 [title]="playlist.name" class="playlist-name text-truncate">{{ playlist.name }}</h3>
  </div>
  <div class="playlist-genre text-truncate">{{ playlist.generalGenre }}</div>
  <div class="playlist-meta text-truncate">
    <span class="playlist-track-count">{{ playlist.trackCount || 0 }} {{ playlist.trackCount === 1 ? 'Track' : 'Tracks'
      }}</span>
    <span class="dot-separator">-</span>
    <span class="playlist-duration">{{ getPlaylistDuration() }}</span>
  </div>

  <div class="player-controls-container">
    <button class="btn player-play-btn rounded-circle d-flex justify-content-center align-items-center p-0" (click)="addToQueue(); $event.stopPropagation()">
      <div class="icon icon-play"></div>
    </button>
  </div>
</div>
import * as moment from 'moment';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from '../../shared/services/auth.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SearchService } from '../../shared/services/search.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { User, Venue } from 'src/app/shared/models/models';
import { UserRoles } from 'src/app/shared/models/consts';
import { environment } from 'src/environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { VenueState } from 'src/app/shared/store/venue/venue.state';
import { VenueListComponent } from '../venues/venue-settings/venue-list/venue-list.component';
import { VenueAction } from 'src/app/shared/store/venue/venue.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Select(VenueState.venue) venue$: Observable<Venue>;
  @Input() inputSideNav: MatSidenav;

  onUserChanged: Subscription;
  user: User;
  onRecentSearchChanges: Subscription;
  recentSearches: any[] = [];
  searchString = '';
  searchRoute = '/search-results';
  recentSearchesActive = false;
  unreadCount: number;
  isProd = !environment.environment || environment.environment === 'production';
  isShowSearchBar = true;

  venueNamesMap: Map<number, string> = new Map();
  unreadCountByVenue: Map<number, number> = new Map();
  public venue: Venue;

  private unSubscribe$: Subject<{}> = new Subject;
  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private searchService: SearchService,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
  ) { 
    this.venue$ = this.store.select(VenueState.venue);
  }

  ngOnInit() {
    this.onUserChanged = this.authService.onUserChanged.subscribe(user => {
      this.user = user;
    });

    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(searchQuery => {
        this.router.navigate(['/user-search'], { queryParams: { query: searchQuery.trim() } });
      });

    this.onRecentSearchChanges = this.searchService.onResentSearchChanges.subscribe((story) => {
      this.recentSearches = story.sort((query1, query2) => {
        return moment(query1).isAfter(query2) ? 1 : -1;
      });

      this.recentSearches.forEach((query) => {
        const date = moment(query.date, 'MM-DD-YYYY h:mm:ss');
        if (date.isValid()) {
          query.date = moment(query.date, 'MM-DD-YYYY h:mm:ss').format('MM/DD/YYYY');
        }
      });
    });

    this.venue$
    .pipe(
      filter((venue) => !!venue),
      takeUntil(this.unSubscribe$)
    )
    .subscribe((venue) => {
      this.venue = venue;
    });
  }

  onSearchChange(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.searchSubject.next(this.searchString);
    }
  }

  changeVenue() {
    this.dialog.open(VenueListComponent, { width: '640px' })
      .afterClosed()
      .subscribe((venueId: number) => {
        if (venueId) {
          this.store.dispatch(new VenueAction.ChangeCurrentVenue(venueId)).subscribe(() => window.location.reload());
        }
      });
  }

  get roleNames() {
    return UserRoles;
  }

  search() {
    if (!this.searchString) {
      return;
    }

    this.searchString = this.searchString.trim();
    this.router.navigate([this.searchRoute, this.searchString]);
  }

  selectRecentSearch(searchString: string) {
    if (searchString) {
      this.searchString = searchString;
      this.search();
    }
  }

  showHideRecentSearches() {
    if (this.searchString.trim() === '') {
      this.recentSearchesActive = true;
      return;
    }

    this.recentSearchesActive = false;
  }

  hideRecentSearches() {
    this.recentSearchesActive = false;
  }

  ngOnDestroy() {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}

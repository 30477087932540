import { CHANNEL_PERMISSION, CURATION_PERMISSION, PROMOTIONS_PERMISSION, VIEW_SCHEDULE_PERMISSION } from './permissions/permissions';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { SystemComponent } from './system.component';
import { environment } from 'src/environments/environment';

const wipRoutes = [
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
]

const routes: Routes = [
  {
    path: 'playground',
    children: [
      { path: '', loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundModule) }
    ]
  },
  {
    path: '',
    component: SystemComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'discover',
        loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule)
      },
      {
        path: 'user-search',
        loadChildren: () => import('./global-search/global-search.module').then(m => m.GlobalSearchModule)
      },
      {
        path: 'schedules-v2-1',
        loadChildren: () => import('./scheduler-full-calendar/scheduler-full-calendar.module').then(m => m.SchedulerFullCalendarModule)
      },
      {
        path: 'library',
        loadChildren: () => import('./library/library.module').then(m => m.LibraryModule)
      },
      {
        path: 'schedules',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: VIEW_SCHEDULE_PERMISSION }},
      },
      {
        path: 'channel-schedule',
        loadChildren: () => import('./schedules/schedules.module').then(m => m.SchedulesModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: [VIEW_SCHEDULE_PERMISSION, CHANNEL_PERMISSION] }},
      },
      {
        path: 'zones',
        loadChildren: () => import('./zones/zones.module').then(m => m.ZonesModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { except: 'user' }},
      },
      {
        path: 'venues',
        loadChildren: () => import('./venues/venues.module').then(m => m.VenuesModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { except: 'user' }},
      },
      {
        path: 'playlist',
        loadChildren: () => import('./playlist/playlist.module').then(m => m.PlaylistModule)
      },
      {
        path: 'video-playlist',
        loadChildren: () => import('./video-playlist/video-playlist.module').then(m => m.VideoPlaylistModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: CHANNEL_PERMISSION }},
      },
      {
        path: 'album',
        loadChildren: () => import('./album/album.module').then(m => m.AlbumModule)
      },
      {
        path: 'artist',
        loadChildren: () => import('./artist/artist.module').then(m => m.ArtistModule)
      },
      {
        path: 'my-teams',
        loadChildren: () => import('./my-teams/my-teams.module').then(m => m.MyTeamsModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { except: 'user' }},
      },
      {
        path: 'search-results',
        loadChildren: () => import('./search-results/search-results.module').then(m => m.SearchResultsModule)
      },
      {
        path: 'view-all',
        loadChildren: () => import('./view-all/view-all.module').then(m => m.ViewAllModule)
      },
      {
        path: 'curation',
        loadChildren: () => import('./general-curation/general-curation.module').then(m => m.GeneralCurationModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: CURATION_PERMISSION }},
      },
      {
        path: 'promotion',
        loadChildren: () => import('./promotions/promotions.module').then(m => m.PromotionsModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { only: PROMOTIONS_PERMISSION }},
      },
      {
        path: 'catalogue-playlists',
        loadChildren: () => import('./catalogue-playlists/catalogue-playlists.module').then(m => m.CataloguePlaylistsModule)
      },
      {
        path: 'jukeo-settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [NgxPermissionsGuard],
        data: { permissions: { except: 'user' }},
      },
      //...((!environment.environment || environment.environment === 'production') ? [] : wipRoutes)
      ...(wipRoutes)
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SystemRoutingModule {
}
